import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { ItkSubnavModule } from '../subnav/subnav.module';
import { ItkSettingsComponent } from './settings.component';
import { ItkButtonComponent } from '@itk/ng-ui';

@NgModule({
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ItkSubnavModule,
    RouterModule,
    ItkButtonComponent,
  ],
  declarations: [ItkSettingsComponent],
})
export class ItkSettingsModule {}
