import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratedApiModule } from '../generated/generated-api.module';
import { ApiConfiguration } from '../generated/api-configuration';
import { HttpClientModule } from '@angular/common/http';
import { ItkClientAuthModuleDeprecated } from '@itk/ng-auth';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ItkClientAuthModuleDeprecated,
    GeneratedApiModule.forRoot({}),
  ],
})
export class ItkEnrollmentApiModule {
  static forRoot(
    provider: Omit<FactoryProvider, 'provide' | 'multi'>,
  ): ModuleWithProviders<ItkEnrollmentApiModule> {
    return {
      ngModule: ItkEnrollmentApiModule,
      providers: [{ ...provider, provide: ApiConfiguration }],
    };
  }
}
