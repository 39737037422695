import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, of } from 'rxjs';
import { BrowserEnvConfig } from '../app.config';
import { ToolkitMetadataService } from '../services/metadata.service';
import { NavLink } from '../types';
import { FEX_TOOLKIT_CODE } from '@itk/types';
import { ToolkitRouteService } from '../services/tookit-route.service';
import { ItkRouterDataFieldsService } from '../services/router-data-fieds.service';
import { ItkMemberService } from '../services/member.service';

@Component({
  selector: 'itk-topnav',
  styleUrl: './topnav.component.scss',
  templateUrl: './topnav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkTopnavComponent {
  constructor(
    public readonly config: BrowserEnvConfig,
    private readonly metadataService: ToolkitMetadataService,
    private readonly member: ItkMemberService,
    private readonly tk: ToolkitRouteService,
    private readonly routerData: ItkRouterDataFieldsService,
  ) {}

  private readonly vueApp$ = of(this.config.vueApp);

  private readonly links$ = combineLatest({
    topNavItems: this.metadataService.topNavigationMenuItems$,
    app: this.vueApp$,
    crmDisabled: this.member.member$.pipe(
      map((x) => x.is_crm_disabled_for_child_accounts),
    ),
  }).pipe(
    map(({ topNavItems, app, crmDisabled }) =>
      topNavItems
        .filter((x) => {
          if (x.slug === '/crm' && crmDisabled) {
            return false;
          }
          return true;
        })
        .map<NavLink>((item) => ({
          name: item.label,
          isExternal: !!item.isLegacy,
          href: `${!item.isLegacy ? '' : app}${item.slug}`,
        })),
    ),
  );

  private readonly dropDownLinks$ = combineLatest({
    metadata: this.metadataService.metadata$,
    app: this.vueApp$,
    isFexTermHidden: this.member.member$.pipe(map((x) => x.is_fex_term_hidden)),
    isDvhHidden: this.member.member$.pipe(map((x) => x.is_dvh_hidden)),
    isMedSuppHidden: this.member.member$.pipe(map((x) => x.is_medsupp_hidden)),
  }).pipe(
    map(({ metadata, app, isFexTermHidden, isDvhHidden, isMedSuppHidden }) =>
      metadata.toolkits
        .filter((x) => {
          if ((x.code === 'TERM' || x.code === 'FEX') && isFexTermHidden) {
            return false;
          }

          // This isn't a typo, HI is supposed to be hidden in this case.
          if ((x.code === 'DVH' || x.code === 'HI') && isDvhHidden) {
            return false;
          }

          if (x.code === 'MEDSUPP' && isMedSuppHidden) {
            return false;
          }
          return true;
        })
        .map<NavLink>((toolkit) => ({
          href: `${!toolkit.isLegacy ? '' : app}${toolkit.slug}`,
          name: toolkit.label,
          isExternal: !!toolkit.isLegacy,
        })),
    ),
  );

  public readonly vm$ = combineLatest({
    hideNavigation: this.routerData.routeDataFields$.pipe(
      map((x) => x.hideNavigation),
    ),
    excludeAgencyLogo: this.routerData.routeDataFields$.pipe(
      map((x) => x.excludeAgencyLogo),
    ),
    dropDownLinks: this.dropDownLinks$,
    links: combineLatest({
      links: this.links$,
      member: this.member.member$,
    }).pipe(
      map(({ links, member }) => {
        return links.filter((y) => {
          if (
            (y.href === '/leads' || y.href === '/leads/') &&
            !member.is_leads_platform_enabled
          ) {
            return false;
          }

          return true;
        });
      }),
    ),
    selectedDropdown: this.tk.currentToolkitRoute$.pipe(
      map((x) => x?.code ?? FEX_TOOLKIT_CODE),
    ),
  });

  trackBy(index: number): number {
    return index;
  }
}
