import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'itk-hamburger-button',
  template: `
    <button class="pl-2 text-xl leading-8 text-primary-color-default">☰</button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkHamburgerButtonComponent {}
