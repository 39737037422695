import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'itk-horizontal-divider',
  template: `
    <span class="my-5 block h-px w-full border border-gray-300"></span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkHorizontalDividerComponent {}
