import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

/**
 * @deprecated
 */
export interface ItkFeatureFlags {
  development: boolean;
}

/**
 * @deprecated
 */
@Injectable()
export class ItkFeatureFlagsService {
  getFeatureFlagsFromParams(route: Params): ItkFeatureFlags {
    return {
      development: route['development'] === 'true' || route['dev'] === 'true',
    };
  }
}
