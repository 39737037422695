import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { ToolkitMetadataService } from '../services/metadata.service';

@Injectable({ providedIn: 'root' })
export class ToolkitGuard {
  constructor(private readonly metadata: ToolkitMetadataService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const toolkit = `/${state.url.split('/')[1]}`;
    return this.metadata.allToolkitSlugs$.pipe(
      map((slugs) => slugs.includes(toolkit)),
      tap((isToolkit) => {
        const value = isToolkit
          ? this.metadata.toolkitGuardSink$.next(toolkit)
          : null;
        return value;
      }),
    );
  }
}
