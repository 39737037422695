import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ItkFeatureFlagsService } from './feature-flags.service';

/**
 * @deprecated
 */
@Injectable()
export class ItkDevelopmentGuard {
  constructor(private readonly featureFlags: ItkFeatureFlagsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.featureFlags.getFeatureFlagsFromParams(route.queryParams)
      .development;
  }
}
