import { Injectable } from '@angular/core';
import { Expose, Transform } from 'class-transformer';
import { IConfig as UnleashProxyClientConfig } from 'unleash-proxy-client';

function convertPathToUrl(path: string): string {
  return /^https?:\/\//.test(path) ? path : `${window.location.origin}${path}`;
}

@Injectable()
export class ItkFeatureFlagConfig implements UnleashProxyClientConfig {
  @Expose()
  @Transform(({ value }) => convertPathToUrl(value))
  url!: string | URL;

  @Expose()
  clientKey!: string;

  @Expose()
  appName!: string;

  @Expose()
  environment?: string;
}
