import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'itk-close-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button class="absolute right-4 top-4 text-xl text-primary-color-default">
      ✕
    </button>
  `,
})
export class ItkCloseButtonComponent {}
