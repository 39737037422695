import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { BrowserEnvConfig } from '../app.config';
import { ToolkitRouteService } from '../services/tookit-route.service';
import {
  DVH_TOOLKIT_CODE,
  FEX_TOOLKIT_CODE,
  HI_TOOLKIT_CODE,
  MEDSUPP_TOOLKIT_CODE,
  TERM_TOOLKIT_CODE,
} from '@itk/types';
import { ItkTokenManagementServiceDeprecated } from '@itk/ng-auth';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkRootRedirectComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private readonly pid: string,
    private readonly config: BrowserEnvConfig,
    private readonly toolkitService: ToolkitRouteService,
    private readonly tokenService: ItkTokenManagementServiceDeprecated,
  ) {}
  ngOnInit(): void {
    if (!isPlatformBrowser(this.pid)) return;

    const isValid = this.tokenService.isValidToken(
      this.tokenService.getAccessToken(),
    );
    if (!isValid) {
      window.location.href = `${this.config.vueApp}/login`;
      return;
    }

    const toolkit = this.toolkitService.getSavedToolkit();
    let tk = 'fex';

    switch (toolkit) {
      case FEX_TOOLKIT_CODE:
        tk = 'fex';
        break;
      case TERM_TOOLKIT_CODE:
        tk = 'term';
        break;
      case MEDSUPP_TOOLKIT_CODE:
        tk = 'medsupp';
        break;
      case HI_TOOLKIT_CODE:
        tk = 'hi';
        break;
      case DVH_TOOLKIT_CODE:
        tk = 'dvh';
        break;
    }

    const href = `/${tk}/quoter/`;
    window.location.href = href;
  }
}
