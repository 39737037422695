<itk-close-button (click)="whenClose.emit()"></itk-close-button>
<itk-logo></itk-logo>

<itk-heading weight="semibold">Toolkits</itk-heading>

<ng-content></ng-content>

<itk-heading weight="semibold">Tools</itk-heading>

<ng-container *ngFor="let tool of tools; trackBy: trackByTool">
  <a *ngIf="tool.isExternal" [href]="tool.href">
    <button
      class="toolkit-button"
      [class]="tool.selected ? 'toolkit-button-selected' : null"
    >
      {{ tool.label }}
    </button>
  </a>

  <a *ngIf="!tool.isExternal" [routerLink]="tool.href">
    <button
      class="toolkit-button"
      [class]="tool.selected ? 'toolkit-button-selected' : null"
    >
      {{ tool.label }}
    </button>
  </a>
</ng-container>

<itk-horizontal-divider></itk-horizontal-divider>

<ng-container *ngFor="let item of menuItems; trackBy: trackByMenuItem">
  <a *ngIf="item.isExternal" [href]="item.href">
    <button class="toolkit-button">
      {{ item.name }}
    </button>
  </a>

  <a *ngIf="!item.isExternal" [routerLink]="item.href">
    <button class="toolkit-button">
      {{ item.name }}
    </button>
  </a>
</ng-container>
