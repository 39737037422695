import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GeneratedApiModule } from '../generated/generated-api.module';
import { ApiConfiguration } from '../generated/api-configuration';

@NgModule({
  imports: [CommonModule, HttpClientModule, GeneratedApiModule.forRoot({})],
})
export class ItkGeographyApiModule {
  static forRoot(
    provider: Omit<FactoryProvider, 'provide' | 'multi'>,
  ): ModuleWithProviders<ItkGeographyApiModule> {
    return {
      ngModule: ItkGeographyApiModule,
      providers: [{ ...provider, provide: ApiConfiguration }],
    };
  }
}
