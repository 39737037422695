import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ItkToastService } from './toast.service';

@Component({
  templateUrl: './toast.component.html',
  selector: 'itk-toast',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkToastComponent {
  constructor(public readonly toastService: ItkToastService) {}
}
