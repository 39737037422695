import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ItkRouterDataFieldsService } from './services/router-data-fieds.service';
import { map } from 'rxjs';

@Component({
  selector: 'itk-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkAppComponent {
  constructor(private readonly routerData: ItkRouterDataFieldsService) {}

  public readonly showTopNav$ = this.routerData.routeDataFields$.pipe(
    map((x) => !x.intendedForIframe),
  );
}
