import { FactoryProvider, NgModule } from '@angular/core';
import { ItkLeadsApiModule } from '@itk/ng-leads-api';
import { ItkEnrollmentApiModule } from '@itk/ng-enrollment-api';
import { ItkGeographyApiModule } from '@itk/ng-geography-api';
import { ItkClientAuthModuleDeprecated } from '@itk/ng-auth';
import { BrowserEnvConfig } from './app.config';
import { ItkNgDjangoApiModule } from '@itk/ng-django-api';

type ConfigType = Omit<FactoryProvider, 'provide' | 'multi'>;

function getUrlFromConfig(key: keyof BrowserEnvConfig) {
  return function getUrlFromConfig1(cfg: BrowserEnvConfig): unknown {
    return {
      rootUrl: cfg[key],
    };
  };
}

function genRootConfig(key: keyof BrowserEnvConfig): ConfigType {
  return {
    useFactory: getUrlFromConfig(key),
    deps: [BrowserEnvConfig],
  };
}

@NgModule({
  imports: [
    ItkClientAuthModuleDeprecated.forRoot({
      useFactory: (cfg: BrowserEnvConfig) =>
        `${cfg['djangoApi']}/token/refresh/`,
      deps: [BrowserEnvConfig],
    }),
    ItkLeadsApiModule.forRoot(genRootConfig('leadsApi')),
    ItkEnrollmentApiModule.forRoot(genRootConfig('enrollmentApi')),
    ItkGeographyApiModule.forRoot(genRootConfig('geoApi')),
    ItkNgDjangoApiModule.forRoot(genRootConfig('djangoApi')),
  ],
})
export class ItkBackendConfigModule {}
