<ng-container *ngIf="vm$ | async as vm">
  <itk-logo class="mx-7" [excludeAgencyLogo]="vm.excludeAgencyLogo" />
  <div
    *ngIf="!vm.hideNavigation"
    class="flex flex-row items-center justify-between max-lg:hidden"
  >
    <itk-dropdown [value]="vm.selectedDropdown" class="mr-7 pr-7">
      <ng-container *ngFor="let x of vm.dropDownLinks; trackBy: trackBy">
        <a *ngIf="x.isExternal" [href]="x.href" class="itk-dropdown-item">{{
          x.name
        }}</a>
        <a
          *ngIf="!x.isExternal"
          [routerLink]="x.href"
          class="itk-dropdown-item"
          >{{ x.name }}</a
        >
      </ng-container>
    </itk-dropdown>
    <itk-link
      *ngFor="let link of vm.links; trackBy: trackBy"
      [location]="link.href"
      [label]="link.name"
      [isExternal]="link.isExternal"
      class="mr-4 pr-4"
    ></itk-link>
  </div>

  <itk-mobile-sidebar class="lg:hidden" *ngIf="!vm.hideNavigation">
    <itk-dropdown [value]="vm.selectedDropdown">
      <a
        *ngFor="let x of vm.dropDownLinks; trackBy: trackBy"
        [routerLink]="x.href"
        class="itk-dropdown-item"
        >{{ x.name }}</a
      >
    </itk-dropdown>
  </itk-mobile-sidebar>
</ng-container>
