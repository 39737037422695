import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ItkRootRedirectComponent } from './root-redirect/root-redirect.component';
import { ToolkitGuard } from './toolkit-guard/toolkit.guard';
import { AuthGuard } from './auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'leads',
    data: { excludeAgencyLogo: true },
    loadChildren: () =>
      import('./leads/leads.module').then((x) => x.ItkLeadsModule),
  },
  //{
  //  path: 'enrollment',
  //  loadChildren: () =>
  //    import('./enrollment/enrollment.module').then(
  //      (x) => x.ItkEnrollmentModule,
  //    ),
  //},
  //{
  //  path: 'upline',
  //  loadChildren: () =>
  //    import('./pocket-upline/pocket-upline.module').then(
  //      (x) => x.ItkPocketUplineModule,
  //    ),
  //},
  {
    path: '',
    component: ItkRootRedirectComponent,
    pathMatch: 'full',
  },
  {
    path: 'settings',
    redirectTo: '/fex/settings/lite',
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./account/account.module').then((x) => x.ItkAccountModule),
  },
  {
    path: 'token',
    loadChildren: () =>
      import('./token-passthrough/token-passthrough.module').then(
        (x) => x.ItkTokenPassthroughModule,
      ),
  },
  { path: 'quoter', redirectTo: 'fex' },
  {
    path: 'dvh',
    canActivate: [ToolkitGuard],
    loadChildren: () => import('./dvh/dvh.module').then((x) => x.ItkDvhModule),
  },
  {
    path: 'fex',
    canActivate: [ToolkitGuard],
    loadChildren: () => import('./fex/fex.module').then((x) => x.ItkFexModule),
  },
  {
    path: 'hi',
    canActivate: [ToolkitGuard],
    loadChildren: () => import('./hi/hi.module').then((x) => x.ItkHiModule),
  },
  { path: 'med-supp', redirectTo: 'medsupp' },
  {
    path: 'medsupp',
    canActivate: [ToolkitGuard],
    loadChildren: () =>
      import('./med-supp/med-supp.module').then((x) => x.ItkMedSuppModule),
  },
  {
    path: 'term',
    canActivate: [ToolkitGuard],
    loadChildren: () =>
      import('./term/term.module').then((x) => x.ItkTermModule),
  },
  {
    path: '**',
    loadChildren: () => import('./404/404.module').then((x) => x.Itk404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class ItkAppRoutingModule {}
