/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AffigeeStatusResponseModelApiModel } from '../models/affigee-status-response-model-api-model';
import { AppSignRequestModelApiModel } from '../models/app-sign-request-model-api-model';
import { AppSignResponseModelApiModel } from '../models/app-sign-response-model-api-model';
import { AppStatusRequestModelApiModel } from '../models/app-status-request-model-api-model';
import { AppStatusResponseModelApiModel } from '../models/app-status-response-model-api-model';
import { GetAppQRequestModelApiModel } from '../models/get-app-q-request-model-api-model';
import { GetAppQResponseModelApiModel } from '../models/get-app-q-response-model-api-model';
import { GetCustomerResponseRequestModelApiModel } from '../models/get-customer-response-request-model-api-model';
import { GetCustomerResponseResponseModelApiModel } from '../models/get-customer-response-response-model-api-model';
import { GetDecisionRequestModelApiModel } from '../models/get-decision-request-model-api-model';
import { GetDecisionResponseModelApiModel } from '../models/get-decision-response-model-api-model';
import { GetFormsRequestModelApiModel } from '../models/get-forms-request-model-api-model';
import { GetFormsResponseModelApiModel } from '../models/get-forms-response-model-api-model';
import { GetPolicyNumberRequestModelApiModel } from '../models/get-policy-number-request-model-api-model';
import { GetPolicyNumberResponseModelApiModel } from '../models/get-policy-number-response-model-api-model';
import { GetPremiumRequestModelApiModel } from '../models/get-premium-request-model-api-model';
import { GetPremiumResponseModelApiModel } from '../models/get-premium-response-model-api-model';
import { GetQuoteRequestModelApiModel } from '../models/get-quote-request-model-api-model';
import { GetQuoteResponseModelApiModel } from '../models/get-quote-response-model-api-model';
import { InitAgentAppRequestModelApiModel } from '../models/init-agent-app-request-model-api-model';
import { InitAgentAppResponseModelApiModel } from '../models/init-agent-app-response-model-api-model';
import { InitAppRequestModelApiModel } from '../models/init-app-request-model-api-model';
import { InitAppResponseModelApiModel } from '../models/init-app-response-model-api-model';
import { InitSessionResponseModelApiModel } from '../models/init-session-response-model-api-model';
import { NotifyApplicantRequestModelApiModel } from '../models/notify-applicant-request-model-api-model';
import { NotifyApplicantResponseModelApiModel } from '../models/notify-applicant-response-model-api-model';
import { OfferAcceptanceRequestModelApiModel } from '../models/offer-acceptance-request-model-api-model';
import { OfferAcceptanceResponseModelApiModel } from '../models/offer-acceptance-response-model-api-model';
import { SubmitAppQaRequestModelApiModel } from '../models/submit-app-qa-request-model-api-model';
import { SubmitAppQaResponseModelApiModel } from '../models/submit-app-qa-response-model-api-model';
import { SubmitAuthRequestModelApiModel } from '../models/submit-auth-request-model-api-model';
import { SubmitAuthResponseModelApiModel } from '../models/submit-auth-response-model-api-model';
import { SubmitBeneRequestModelApiModel } from '../models/submit-bene-request-model-api-model';
import { SubmitBeneResponseModelApiModel } from '../models/submit-bene-response-model-api-model';
import { SubmitPayInfoRequestModelApiModel } from '../models/submit-pay-info-request-model-api-model';
import { SubmitPayInfoResponseModelApiModel } from '../models/submit-pay-info-response-model-api-model';
import { UpdatePiiRequestModelApiModel } from '../models/update-pii-request-model-api-model';
import { UpdatePiiResponseModelApiModel } from '../models/update-pii-response-model-api-model';
import { UpdateSessionRequestModelApiModel } from '../models/update-session-request-model-api-model';
import { UpdateSessionResponseModelApiModel } from '../models/update-session-response-model-api-model';

@Injectable({
  providedIn: 'root',
})
export class ItkEnrollmentAfficiencySAffigeeApiProxyApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerAffigeeStatus
   */
  static readonly ItkEnrollmentAfficiencyControllerAffigeeStatusPath =
    '/afficiency/affigeeStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerAffigeeStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  itkEnrollmentAfficiencyControllerAffigeeStatus$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<AffigeeStatusResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerAffigeeStatusPath,
      'post',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AffigeeStatusResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerAffigeeStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itkEnrollmentAfficiencyControllerAffigeeStatus(params?: {
    context?: HttpContext;
  }): Observable<AffigeeStatusResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerAffigeeStatus$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<AffigeeStatusResponseModelApiModel>) =>
          r.body as AffigeeStatusResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerAppStatus
   */
  static readonly ItkEnrollmentAfficiencyControllerAppStatusPath =
    '/afficiency/appStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerAppStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerAppStatus$Response(params: {
    context?: HttpContext;
    body: AppStatusRequestModelApiModel;
  }): Observable<StrictHttpResponse<AppStatusResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerAppStatusPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppStatusResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerAppStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerAppStatus(params: {
    context?: HttpContext;
    body: AppStatusRequestModelApiModel;
  }): Observable<AppStatusResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerAppStatus$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<AppStatusResponseModelApiModel>) =>
          r.body as AppStatusResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerInitAgentApp
   */
  static readonly ItkEnrollmentAfficiencyControllerInitAgentAppPath =
    '/afficiency/initAgentApp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerInitAgentApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerInitAgentApp$Response(params: {
    context?: HttpContext;
    body: InitAgentAppRequestModelApiModel;
  }): Observable<StrictHttpResponse<InitAgentAppResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerInitAgentAppPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<InitAgentAppResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerInitAgentApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerInitAgentApp(params: {
    context?: HttpContext;
    body: InitAgentAppRequestModelApiModel;
  }): Observable<InitAgentAppResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerInitAgentApp$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<InitAgentAppResponseModelApiModel>) =>
          r.body as InitAgentAppResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerInitSession
   */
  static readonly ItkEnrollmentAfficiencyControllerInitSessionPath =
    '/afficiency/initSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerInitSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  itkEnrollmentAfficiencyControllerInitSession$Response(params?: {
    context?: HttpContext;
  }): Observable<StrictHttpResponse<InitSessionResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerInitSessionPath,
      'post',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<InitSessionResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerInitSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  itkEnrollmentAfficiencyControllerInitSession(params?: {
    context?: HttpContext;
  }): Observable<InitSessionResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerInitSession$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<InitSessionResponseModelApiModel>) =>
          r.body as InitSessionResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerUpdatePii
   */
  static readonly ItkEnrollmentAfficiencyControllerUpdatePiiPath =
    '/afficiency/updatePII';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerUpdatePii()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerUpdatePii$Response(params: {
    context?: HttpContext;
    body: UpdatePiiRequestModelApiModel;
  }): Observable<StrictHttpResponse<UpdatePiiResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerUpdatePiiPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UpdatePiiResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerUpdatePii$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerUpdatePii(params: {
    context?: HttpContext;
    body: UpdatePiiRequestModelApiModel;
  }): Observable<UpdatePiiResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerUpdatePii$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<UpdatePiiResponseModelApiModel>) =>
          r.body as UpdatePiiResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetAppQ
   */
  static readonly ItkEnrollmentAfficiencyControllerGetAppQPath =
    '/afficiency/getAppQ';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetAppQ()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetAppQ$Response(params: {
    context?: HttpContext;
    body: GetAppQRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetAppQResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetAppQPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetAppQResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetAppQ$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetAppQ(params: {
    context?: HttpContext;
    body: GetAppQRequestModelApiModel;
  }): Observable<GetAppQResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetAppQ$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetAppQResponseModelApiModel>) =>
          r.body as GetAppQResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerInitApp
   */
  static readonly ItkEnrollmentAfficiencyControllerInitAppPath =
    '/afficiency/initApp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerInitApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerInitApp$Response(params: {
    context?: HttpContext;
    body: InitAppRequestModelApiModel;
  }): Observable<StrictHttpResponse<InitAppResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerInitAppPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<InitAppResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerInitApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerInitApp(params: {
    context?: HttpContext;
    body: InitAppRequestModelApiModel;
  }): Observable<InitAppResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerInitApp$Response(params).pipe(
      map(
        (r: StrictHttpResponse<InitAppResponseModelApiModel>) =>
          r.body as InitAppResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetQuote
   */
  static readonly ItkEnrollmentAfficiencyControllerGetQuotePath =
    '/afficiency/getQuote';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetQuote$Response(params: {
    context?: HttpContext;
    body: GetQuoteRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetQuoteResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetQuotePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetQuoteResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetQuote(params: {
    context?: HttpContext;
    body: GetQuoteRequestModelApiModel;
  }): Observable<GetQuoteResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetQuote$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetQuoteResponseModelApiModel>) =>
          r.body as GetQuoteResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerSubmitAppQa
   */
  static readonly ItkEnrollmentAfficiencyControllerSubmitAppQaPath =
    '/afficiency/submitAppQA';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerSubmitAppQa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitAppQa$Response(params: {
    context?: HttpContext;
    body: SubmitAppQaRequestModelApiModel;
  }): Observable<StrictHttpResponse<SubmitAppQaResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerSubmitAppQaPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubmitAppQaResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerSubmitAppQa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitAppQa(params: {
    context?: HttpContext;
    body: SubmitAppQaRequestModelApiModel;
  }): Observable<SubmitAppQaResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerSubmitAppQa$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<SubmitAppQaResponseModelApiModel>) =>
          r.body as SubmitAppQaResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerSubmitBene
   */
  static readonly ItkEnrollmentAfficiencyControllerSubmitBenePath =
    '/afficiency/submitBene';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerSubmitBene()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitBene$Response(params: {
    context?: HttpContext;
    body: SubmitBeneRequestModelApiModel;
  }): Observable<StrictHttpResponse<SubmitBeneResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerSubmitBenePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubmitBeneResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerSubmitBene$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitBene(params: {
    context?: HttpContext;
    body: SubmitBeneRequestModelApiModel;
  }): Observable<SubmitBeneResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerSubmitBene$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<SubmitBeneResponseModelApiModel>) =>
          r.body as SubmitBeneResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerSubmitAuth
   */
  static readonly ItkEnrollmentAfficiencyControllerSubmitAuthPath =
    '/afficiency/submitAuth';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerSubmitAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitAuth$Response(params: {
    context?: HttpContext;
    body: SubmitAuthRequestModelApiModel;
  }): Observable<StrictHttpResponse<SubmitAuthResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerSubmitAuthPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubmitAuthResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerSubmitAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitAuth(params: {
    context?: HttpContext;
    body: SubmitAuthRequestModelApiModel;
  }): Observable<SubmitAuthResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerSubmitAuth$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<SubmitAuthResponseModelApiModel>) =>
          r.body as SubmitAuthResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerNotifyApplicant
   */
  static readonly ItkEnrollmentAfficiencyControllerNotifyApplicantPath =
    '/afficiency/notifyApplicant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerNotifyApplicant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerNotifyApplicant$Response(params: {
    context?: HttpContext;
    body: NotifyApplicantRequestModelApiModel;
  }): Observable<StrictHttpResponse<NotifyApplicantResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerNotifyApplicantPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotifyApplicantResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerNotifyApplicant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerNotifyApplicant(params: {
    context?: HttpContext;
    body: NotifyApplicantRequestModelApiModel;
  }): Observable<NotifyApplicantResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerNotifyApplicant$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<NotifyApplicantResponseModelApiModel>) =>
          r.body as NotifyApplicantResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerAppSign
   */
  static readonly ItkEnrollmentAfficiencyControllerAppSignPath =
    '/afficiency/appSign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerAppSign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerAppSign$Response(params: {
    context?: HttpContext;
    body: AppSignRequestModelApiModel;
  }): Observable<StrictHttpResponse<AppSignResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerAppSignPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppSignResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerAppSign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerAppSign(params: {
    context?: HttpContext;
    body: AppSignRequestModelApiModel;
  }): Observable<AppSignResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerAppSign$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AppSignResponseModelApiModel>) =>
          r.body as AppSignResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetCustomerResponse
   */
  static readonly ItkEnrollmentAfficiencyControllerGetCustomerResponsePath =
    '/afficiency/getCustomerResponse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetCustomerResponse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetCustomerResponse$Response(params: {
    context?: HttpContext;
    body: GetCustomerResponseRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetCustomerResponseResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetCustomerResponsePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetCustomerResponseResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetCustomerResponse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetCustomerResponse(params: {
    context?: HttpContext;
    body: GetCustomerResponseRequestModelApiModel;
  }): Observable<GetCustomerResponseResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetCustomerResponse$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<GetCustomerResponseResponseModelApiModel>) =>
          r.body as GetCustomerResponseResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetDecision
   */
  static readonly ItkEnrollmentAfficiencyControllerGetDecisionPath =
    '/afficiency/getDecision';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetDecision()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetDecision$Response(params: {
    context?: HttpContext;
    body: GetDecisionRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetDecisionResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetDecisionPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetDecisionResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetDecision$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetDecision(params: {
    context?: HttpContext;
    body: GetDecisionRequestModelApiModel;
  }): Observable<GetDecisionResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetDecision$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<GetDecisionResponseModelApiModel>) =>
          r.body as GetDecisionResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetPremium
   */
  static readonly ItkEnrollmentAfficiencyControllerGetPremiumPath =
    '/afficiency/getPremium';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetPremium()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetPremium$Response(params: {
    context?: HttpContext;
    body: GetPremiumRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetPremiumResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetPremiumPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetPremiumResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetPremium$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetPremium(params: {
    context?: HttpContext;
    body: GetPremiumRequestModelApiModel;
  }): Observable<GetPremiumResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetPremium$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<GetPremiumResponseModelApiModel>) =>
          r.body as GetPremiumResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerOfferAcceptance
   */
  static readonly ItkEnrollmentAfficiencyControllerOfferAcceptancePath =
    '/afficiency/offerAcceptance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerOfferAcceptance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerOfferAcceptance$Response(params: {
    context?: HttpContext;
    body: OfferAcceptanceRequestModelApiModel;
  }): Observable<StrictHttpResponse<OfferAcceptanceResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerOfferAcceptancePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OfferAcceptanceResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerOfferAcceptance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerOfferAcceptance(params: {
    context?: HttpContext;
    body: OfferAcceptanceRequestModelApiModel;
  }): Observable<OfferAcceptanceResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerOfferAcceptance$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<OfferAcceptanceResponseModelApiModel>) =>
          r.body as OfferAcceptanceResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetForms
   */
  static readonly ItkEnrollmentAfficiencyControllerGetFormsPath =
    '/afficiency/getForms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetForms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetForms$Response(params: {
    context?: HttpContext;
    body: GetFormsRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetFormsResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetFormsPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFormsResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetForms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetForms(params: {
    context?: HttpContext;
    body: GetFormsRequestModelApiModel;
  }): Observable<GetFormsResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetForms$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFormsResponseModelApiModel>) =>
          r.body as GetFormsResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerGetPolicyNumber
   */
  static readonly ItkEnrollmentAfficiencyControllerGetPolicyNumberPath =
    '/afficiency/getPolicyNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerGetPolicyNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetPolicyNumber$Response(params: {
    context?: HttpContext;
    body: GetPolicyNumberRequestModelApiModel;
  }): Observable<StrictHttpResponse<GetPolicyNumberResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerGetPolicyNumberPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetPolicyNumberResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerGetPolicyNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerGetPolicyNumber(params: {
    context?: HttpContext;
    body: GetPolicyNumberRequestModelApiModel;
  }): Observable<GetPolicyNumberResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerGetPolicyNumber$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<GetPolicyNumberResponseModelApiModel>) =>
          r.body as GetPolicyNumberResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerSubmitPayInfo
   */
  static readonly ItkEnrollmentAfficiencyControllerSubmitPayInfoPath =
    '/afficiency/submitPayInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerSubmitPayInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitPayInfo$Response(params: {
    context?: HttpContext;
    body: SubmitPayInfoRequestModelApiModel;
  }): Observable<StrictHttpResponse<SubmitPayInfoResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerSubmitPayInfoPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubmitPayInfoResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerSubmitPayInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerSubmitPayInfo(params: {
    context?: HttpContext;
    body: SubmitPayInfoRequestModelApiModel;
  }): Observable<SubmitPayInfoResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerSubmitPayInfo$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<SubmitPayInfoResponseModelApiModel>) =>
          r.body as SubmitPayInfoResponseModelApiModel,
      ),
    );
  }

  /**
   * Path part for operation itkEnrollmentAfficiencyControllerUpdateSession
   */
  static readonly ItkEnrollmentAfficiencyControllerUpdateSessionPath =
    '/afficiency/updateSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `itkEnrollmentAfficiencyControllerUpdateSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerUpdateSession$Response(params: {
    context?: HttpContext;
    body: UpdateSessionRequestModelApiModel;
  }): Observable<StrictHttpResponse<UpdateSessionResponseModelApiModel>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ItkEnrollmentAfficiencySAffigeeApiProxyApi.ItkEnrollmentAfficiencyControllerUpdateSessionPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context,
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UpdateSessionResponseModelApiModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `itkEnrollmentAfficiencyControllerUpdateSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  itkEnrollmentAfficiencyControllerUpdateSession(params: {
    context?: HttpContext;
    body: UpdateSessionRequestModelApiModel;
  }): Observable<UpdateSessionResponseModelApiModel> {
    return this.itkEnrollmentAfficiencyControllerUpdateSession$Response(
      params,
    ).pipe(
      map(
        (r: StrictHttpResponse<UpdateSessionResponseModelApiModel>) =>
          r.body as UpdateSessionResponseModelApiModel,
      ),
    );
  }
}
