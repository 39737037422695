<itk-sidebar-menu
  *ngIf="expanded$ | async"
  [tools]="tools$ | async"
  (whenClose)="closeMenu()"
  [menuItems]="menuItems$ | async"
>
  <ng-content select="itk-dropdown"></ng-content>
</itk-sidebar-menu>

<itk-hamburger-button (click)="openMenu()" class="mx-7"></itk-hamburger-button>
