import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  Subject,
  distinctUntilChanged,
  startWith,
  map,
  combineLatest,
  of,
  filter,
} from 'rxjs';
import { BrowserEnvConfig } from '../app.config';
import { ToolkitMetadataService } from '../services/metadata.service';
import { NavLink } from '../types';
import { ToolSidebarButtonConfig } from '../sidebar-menu/sidebar-menu.component';
import { EventType, Router } from '@angular/router';

@Component({
  selector: 'itk-mobile-sidebar',
  templateUrl: 'mobile-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSideBarComponent implements OnDestroy {
  constructor(
    private readonly metadataService: ToolkitMetadataService,
    private readonly config: BrowserEnvConfig,
    private readonly router: Router,
  ) {}

  private readonly closeOnRouterEventSub = this.router.events
    .pipe(
      filter(
        (x) =>
          x.type === EventType.ActivationEnd ||
          x.type == EventType.NavigationSkipped,
      ),
    )
    .subscribe(() => this.closeMenu());

  private readonly expandedSource$ = new Subject<boolean>();

  private readonly app$ = of(this.config.vueApp);

  public readonly expanded$ = this.expandedSource$.pipe(
    distinctUntilChanged(),
    startWith(false),
  );

  public readonly tools$ = combineLatest([
    this.metadataService.currentToolkit$,
    this.app$,
  ]).pipe(
    map(([toolkit, legacyApp]) =>
      toolkit.tools.map<ToolSidebarButtonConfig>((tool) => ({
        label: tool.label,
        selected: false,
        isExternal: tool.isLegacy ?? false,
        href: `${!tool.isLegacy ? '' : legacyApp}${tool.slug}`,
      })),
    ),
  );

  public readonly menuItems$ = combineLatest([
    this.metadataService.navigationMenuItems$,
    this.app$,
  ]).pipe(
    map(([menuItems, legacyApp]) =>
      menuItems.map<NavLink>((item) => ({
        name: item.label,
        isExternal: item.isLegacy ?? false,
        href: `${!item.isLegacy ? '' : legacyApp}${item.slug}`,
      })),
    ),
  );

  public closeMenu(): void {
    return this.expandedSource$.next(false);
  }

  public openMenu(): void {
    return this.expandedSource$.next(true);
  }

  ngOnDestroy(): void {
    this.closeOnRouterEventSub.unsubscribe();
  }
}
