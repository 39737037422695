import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileSideBarComponent } from './mobile-sidebar.component';
import { ItkHamburgerButtonComponent } from '@itk/ng-ui';
import { ItkSidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';

@NgModule({
  exports: [MobileSideBarComponent],
  declarations: [MobileSideBarComponent],
  imports: [CommonModule, ItkHamburgerButtonComponent, ItkSidebarMenuComponent],
})
export class ItkMobileSideBarModule {}
