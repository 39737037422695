import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavLink } from '../types';
import {
  ItkCloseButtonComponent,
  ItkHeadingComponent,
  ItkHorizontalDividerComponent,
  ItkTableHeaderComponent,
} from '@itk/ng-ui';
import { CommonModule } from '@angular/common';
import { ItkLogoComponent } from '../logo/logo.component';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'itk-sidebar-menu',
  styleUrl: './sidebar-menu.component.scss',
  templateUrl: 'sidebar-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ItkCloseButtonComponent,
    ItkTableHeaderComponent,
    ItkHorizontalDividerComponent,
    ItkLogoComponent,
    ItkHeadingComponent,
    RouterLink,
  ],
})
export class ItkSidebarMenuComponent {
  @Output() whenClose = new EventEmitter<void>();

  @Input()
  public tools?: ToolSidebarButtonConfig[] | null = [];

  @Input()
  public menuItems?: NavLink[] | null = [];

  public trackByTool(_: number, tool: ToolSidebarButtonConfig): string {
    return tool.label;
  }

  public trackByMenuItem(index: number, menuItem: NavLink): string {
    return menuItem.name;
  }
}

export interface ToolSidebarButtonConfig {
  label: string;
  selected: boolean;
  href: string;
  isExternal?: boolean;
}
