import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItkToastComponent } from './toast.component';
import { ItkToastService } from './toast.service';

@NgModule({
  declarations: [ItkToastComponent],
  providers: [ItkToastService],
  exports: [ItkToastComponent],
  imports: [CommonModule],
})
export class ItkToastModule {}
