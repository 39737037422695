<ng-container *ngIf="toastService.vm$ | async as vm">
  <div
    class="fixed z-5000 p-1 text-center text-white shadow max-sm:left-0 max-sm:top-0 max-sm:w-full sm:right-24 sm:top-16 sm:rounded"
    *ngIf="vm.show"
    [ngClass]="vm.severity"
    (click)="toastService.clearToast()"
  >
    {{ vm.message }}
  </div>
</ng-container>
