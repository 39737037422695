import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ItkMobileSideBarModule } from '../mobile-sidebar/mobile-sidebar.module';
import { ItkTopnavComponent } from './topnav.component';
import { ItkLogoComponent } from '../logo/logo.component';
import { ItkDropdownComponent, ItkLinkComponent } from '@itk/ng-ui';
import { RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [ItkTopnavComponent],
  imports: [
    CommonModule,
    RouterLink,
    ItkMobileSideBarModule,
    ItkLogoComponent,
    ItkLinkComponent,
    ItkDropdownComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [ItkTopnavComponent],
})
export class ItkTopnavModule {}
