import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItkDevelopmentFlagDirective } from './development-flag.directive';
import { ItkDevelopmentGuard } from './development-flag.guard';
import { ItkFeatureFlagsService } from './feature-flags.service';

/**
 * @deprecated
 */
@NgModule({
  imports: [CommonModule],
  exports: [ItkDevelopmentFlagDirective],
  declarations: [ItkDevelopmentFlagDirective],
  providers: [ItkFeatureFlagsService, ItkDevelopmentGuard],
})
export class ItkFeatureFlagsModule {}
