var t = function (e, r) {
  return t = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (t, e) {
    t.__proto__ = e;
  } || function (t, e) {
    for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && (t[r] = e[r]);
  }, t(e, r);
};
var e = function () {
  return e = Object.assign || function (t) {
    for (var e, r = 1, n = arguments.length; r < n; r++) for (var o in e = arguments[r]) Object.prototype.hasOwnProperty.call(e, o) && (t[o] = e[o]);
    return t;
  }, e.apply(this, arguments);
};
function r(t, e, r, n) {
  return new (r || (r = Promise))(function (o, i) {
    function s(t) {
      try {
        c(n.next(t));
      } catch (t) {
        i(t);
      }
    }
    function a(t) {
      try {
        c(n.throw(t));
      } catch (t) {
        i(t);
      }
    }
    function c(t) {
      var e;
      t.done ? o(t.value) : (e = t.value, e instanceof r ? e : new r(function (t) {
        t(e);
      })).then(s, a);
    }
    c((n = n.apply(t, e || [])).next());
  });
}
function n(t, e) {
  var r,
    n,
    o,
    i,
    s = {
      label: 0,
      sent: function () {
        if (1 & o[0]) throw o[1];
        return o[1];
      },
      trys: [],
      ops: []
    };
  return i = {
    next: a(0),
    throw: a(1),
    return: a(2)
  }, "function" == typeof Symbol && (i[Symbol.iterator] = function () {
    return this;
  }), i;
  function a(a) {
    return function (c) {
      return function (a) {
        if (r) throw new TypeError("Generator is already executing.");
        for (; i && (i = 0, a[0] && (s = 0)), s;) try {
          if (r = 1, n && (o = 2 & a[0] ? n.return : a[0] ? n.throw || ((o = n.return) && o.call(n), 0) : n.next) && !(o = o.call(n, a[1])).done) return o;
          switch (n = 0, o && (a = [2 & a[0], o.value]), a[0]) {
            case 0:
            case 1:
              o = a;
              break;
            case 4:
              return s.label++, {
                value: a[1],
                done: !1
              };
            case 5:
              s.label++, n = a[1], a = [0];
              continue;
            case 7:
              a = s.ops.pop(), s.trys.pop();
              continue;
            default:
              if (!(o = s.trys, (o = o.length > 0 && o[o.length - 1]) || 6 !== a[0] && 2 !== a[0])) {
                s = 0;
                continue;
              }
              if (3 === a[0] && (!o || a[1] > o[0] && a[1] < o[3])) {
                s.label = a[1];
                break;
              }
              if (6 === a[0] && s.label < o[1]) {
                s.label = o[1], o = a;
                break;
              }
              if (o && s.label < o[2]) {
                s.label = o[2], s.ops.push(a);
                break;
              }
              o[2] && s.ops.pop(), s.trys.pop();
              continue;
          }
          a = e.call(t, s);
        } catch (t) {
          a = [6, t], n = 0;
        } finally {
          r = o = 0;
        }
        if (5 & a[0]) throw a[1];
        return {
          value: a[0] ? a[1] : void 0,
          done: !0
        };
      }([a, c]);
    };
  }
}
"function" == typeof SuppressedError && SuppressedError;
var o = {
  exports: {}
};
function i() {}
i.prototype = {
  on: function (t, e, r) {
    var n = this.e || (this.e = {});
    return (n[t] || (n[t] = [])).push({
      fn: e,
      ctx: r
    }), this;
  },
  once: function (t, e, r) {
    var n = this;
    function o() {
      n.off(t, o), e.apply(r, arguments);
    }
    return o._ = e, this.on(t, o, r);
  },
  emit: function (t) {
    for (var e = [].slice.call(arguments, 1), r = ((this.e || (this.e = {}))[t] || []).slice(), n = 0, o = r.length; n < o; n++) r[n].fn.apply(r[n].ctx, e);
    return this;
  },
  off: function (t, e) {
    var r = this.e || (this.e = {}),
      n = r[t],
      o = [];
    if (n && e) for (var i = 0, s = n.length; i < s; i++) n[i].fn !== e && n[i].fn._ !== e && o.push(n[i]);
    return o.length ? r[t] = o : delete r[t], this;
  }
}, o.exports = i;
var s = o.exports.TinyEmitter = i,
  a = function (t) {
    var e = t[1];
    return null != e;
  },
  c = function () {},
  u = function () {
    function t(t) {
      var e = t.onError,
        r = t.onSent,
        n = t.appName,
        o = t.metricsInterval,
        i = t.disableMetrics,
        s = void 0 !== i && i,
        a = t.url,
        u = t.clientKey,
        l = t.fetch,
        h = t.headerName,
        p = t.customHeaders,
        f = void 0 === p ? {} : p,
        d = t.metricsIntervalInitial;
      this.onError = e, this.onSent = r || c, this.disabled = s, this.metricsInterval = 1e3 * o, this.metricsIntervalInitial = 1e3 * d, this.appName = n, this.url = a instanceof URL ? a : new URL(a), this.clientKey = u, this.bucket = this.createEmptyBucket(), this.fetch = l, this.headerName = h, this.customHeaders = f;
    }
    return t.prototype.start = function () {
      var t = this;
      if (this.disabled) return !1;
      "number" == typeof this.metricsInterval && this.metricsInterval > 0 && (this.metricsIntervalInitial > 0 ? setTimeout(function () {
        t.startTimer(), t.sendMetrics();
      }, this.metricsIntervalInitial) : this.startTimer());
    }, t.prototype.stop = function () {
      this.timer && (clearTimeout(this.timer), delete this.timer);
    }, t.prototype.createEmptyBucket = function () {
      return {
        start: new Date(),
        stop: null,
        toggles: {}
      };
    }, t.prototype.getHeaders = function () {
      var t,
        e = ((t = {})[this.headerName] = this.clientKey, t.Accept = "application/json", t["Content-Type"] = "application/json", t);
      return Object.entries(this.customHeaders).filter(a).forEach(function (t) {
        var r = t[0],
          n = t[1];
        return e[r] = n;
      }), e;
    }, t.prototype.sendMetrics = function () {
      return r(this, void 0, void 0, function () {
        var t, e, r;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              if (t = "".concat(this.url, "/client/metrics"), e = this.getPayload(), this.bucketIsEmpty(e)) return [2];
              n.label = 1;
            case 1:
              return n.trys.push([1, 3,, 4]), [4, this.fetch(t, {
                cache: "no-cache",
                method: "POST",
                headers: this.getHeaders(),
                body: JSON.stringify(e)
              })];
            case 2:
              return n.sent(), this.onSent(e), [3, 4];
            case 3:
              return r = n.sent(), console.error("Unleash: unable to send feature metrics", r), this.onError(r), [3, 4];
            case 4:
              return [2];
          }
        });
      });
    }, t.prototype.count = function (t, e) {
      return !(this.disabled || !this.bucket) && (this.assertBucket(t), this.bucket.toggles[t][e ? "yes" : "no"]++, !0);
    }, t.prototype.countVariant = function (t, e) {
      return !(this.disabled || !this.bucket) && (this.assertBucket(t), this.bucket.toggles[t].variants[e] ? this.bucket.toggles[t].variants[e] += 1 : this.bucket.toggles[t].variants[e] = 1, !0);
    }, t.prototype.assertBucket = function (t) {
      if (this.disabled || !this.bucket) return !1;
      this.bucket.toggles[t] || (this.bucket.toggles[t] = {
        yes: 0,
        no: 0,
        variants: {}
      });
    }, t.prototype.startTimer = function () {
      var t = this;
      this.timer = setInterval(function () {
        t.sendMetrics();
      }, this.metricsInterval);
    }, t.prototype.bucketIsEmpty = function (t) {
      return 0 === Object.keys(t.bucket.toggles).length;
    }, t.prototype.getPayload = function () {
      var t = e(e({}, this.bucket), {
        stop: new Date()
      });
      return this.bucket = this.createEmptyBucket(), {
        bucket: t,
        appName: this.appName,
        instanceId: "browser"
      };
    }, t;
  }(),
  l = function () {
    function t() {
      this.store = new Map();
    }
    return t.prototype.save = function (t, e) {
      return r(this, void 0, void 0, function () {
        return n(this, function (r) {
          return this.store.set(t, e), [2];
        });
      });
    }, t.prototype.get = function (t) {
      return r(this, void 0, void 0, function () {
        return n(this, function (e) {
          return [2, this.store.get(t)];
        });
      });
    }, t;
  }(),
  h = function () {
    function t() {
      this.prefix = "unleash:repository";
    }
    return t.prototype.save = function (t, e) {
      return r(this, void 0, void 0, function () {
        var r, o;
        return n(this, function (n) {
          r = JSON.stringify(e), o = "".concat(this.prefix, ":").concat(t);
          try {
            window.localStorage.setItem(o, r);
          } catch (t) {
            console.error(t);
          }
          return [2];
        });
      });
    }, t.prototype.get = function (t) {
      try {
        var e = "".concat(this.prefix, ":").concat(t),
          r = window.localStorage.getItem(e);
        return r ? JSON.parse(r) : void 0;
      } catch (t) {
        console.error(t);
      }
    }, t;
  }();
let p;
const f = new Uint8Array(16);
function d() {
  if (!p && (p = "undefined" != typeof crypto && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !p)) throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return p(f);
}
const v = [];
for (let t = 0; t < 256; ++t) v.push((t + 256).toString(16).slice(1));
var m = {
  randomUUID: "undefined" != typeof crypto && crypto.randomUUID && crypto.randomUUID.bind(crypto)
};
function y(t, e, r) {
  if (m.randomUUID && !e && !t) return m.randomUUID();
  const n = (t = t || {}).random || (t.rng || d)();
  if (n[6] = 15 & n[6] | 64, n[8] = 63 & n[8] | 128, e) {
    r = r || 0;
    for (let t = 0; t < 16; ++t) e[r + t] = n[t];
    return e;
  }
  return function (t, e = 0) {
    return v[t[e + 0]] + v[t[e + 1]] + v[t[e + 2]] + v[t[e + 3]] + "-" + v[t[e + 4]] + v[t[e + 5]] + "-" + v[t[e + 6]] + v[t[e + 7]] + "-" + v[t[e + 8]] + v[t[e + 9]] + "-" + v[t[e + 10]] + v[t[e + 11]] + v[t[e + 12]] + v[t[e + 13]] + v[t[e + 14]] + v[t[e + 15]];
  }(n);
}
var g = function () {
    function t() {}
    return t.prototype.generateEventId = function () {
      return y();
    }, t.prototype.createImpressionEvent = function (t, r, n, o, i, s) {
      var a = this.createBaseEvent(t, r, n, o, i);
      return s ? e(e({}, a), {
        variant: s
      }) : a;
    }, t.prototype.createBaseEvent = function (t, e, r, n, o) {
      return {
        eventType: n,
        eventId: this.generateEventId(),
        context: t,
        enabled: e,
        featureName: r,
        impressionData: o
      };
    }, t;
  }(),
  b = ["userId", "sessionId", "remoteAddress", "currentTime"],
  w = function (t) {
    return b.includes(t);
  },
  E = {
    INIT: "initialized",
    ERROR: "error",
    READY: "ready",
    UPDATE: "update",
    IMPRESSION: "impression",
    SENT: "sent",
    RECOVERED: "recovered"
  },
  I = "isEnabled",
  S = "getVariant",
  x = {
    name: "disabled",
    enabled: !1,
    feature_enabled: !1
  },
  T = "repo",
  R = function () {
    try {
      if ("undefined" != typeof window && "fetch" in window) return fetch.bind(window);
      if ("fetch" in globalThis) return fetch.bind(globalThis);
    } catch (t) {
      console.error('Unleash failed to resolve "fetch"', t);
    }
  },
  k = function (o) {
    function i(t) {
      var r = t.storageProvider,
        n = t.url,
        i = t.clientKey,
        s = t.disableRefresh,
        a = void 0 !== s && s,
        c = t.refreshInterval,
        p = void 0 === c ? 30 : c,
        f = t.metricsInterval,
        d = void 0 === f ? 30 : f,
        v = t.metricsIntervalInitial,
        m = void 0 === v ? 2 : v,
        y = t.disableMetrics,
        b = void 0 !== y && y,
        w = t.appName,
        I = t.environment,
        S = void 0 === I ? "default" : I,
        x = t.context,
        T = t.fetch,
        k = void 0 === T ? R() : T,
        O = t.createAbortController,
        N = void 0 === O ? function () {
          try {
            if ("undefined" != typeof window && "AbortController" in window) return function () {
              return new window.AbortController();
            };
            if ("fetch" in globalThis) return function () {
              return new globalThis.AbortController();
            };
          } catch (t) {
            console.error('Unleash failed to resolve "AbortController" factory', t);
          }
        }() : O,
        A = t.bootstrap,
        D = t.bootstrapOverride,
        U = void 0 === D || D,
        C = t.headerName,
        P = void 0 === C ? "Authorization" : C,
        j = t.customHeaders,
        H = void 0 === j ? {} : j,
        M = t.impressionDataAll,
        _ = void 0 !== M && M,
        K = t.usePOSTrequests,
        V = void 0 !== K && K,
        q = o.call(this) || this;
      if (q.toggles = [], q.etag = "", q.readyEventEmitted = !1, q.fetchedFromServer = !1, q.usePOSTrequests = !1, q.started = !1, !n) throw new Error("url is required");
      if (!i) throw new Error("clientKey is required");
      if (!w) throw new Error("appName is required.");
      return q.eventsHandler = new g(), q.impressionDataAll = _, q.toggles = A && A.length > 0 ? A : [], q.url = n instanceof URL ? n : new URL(n), q.clientKey = i, q.headerName = P, q.customHeaders = H, q.storage = r || ("undefined" != typeof window ? new h() : new l()), q.refreshInterval = a ? 0 : 1e3 * p, q.context = e({
        appName: w,
        environment: S
      }, x), q.usePOSTrequests = V, q.sdkState = "initializing", q.ready = new Promise(function (t) {
        q.init().then(t).catch(function (e) {
          console.error(e), q.sdkState = "error", q.emit(E.ERROR, e), q.lastError = e, t();
        });
      }), k || console.error('Unleash: You must either provide your own "fetch" implementation or run in an environment where "fetch" is available.'), N || console.error('Unleash: You must either provide your own "AbortController" implementation or run in an environment where "AbortController" is available.'), q.fetch = k, q.createAbortController = N, q.bootstrap = A && A.length > 0 ? A : void 0, q.bootstrapOverride = U, q.metrics = new u({
        onError: q.emit.bind(q, E.ERROR),
        onSent: q.emit.bind(q, E.SENT),
        appName: w,
        metricsInterval: d,
        disableMetrics: b,
        url: q.url,
        clientKey: i,
        fetch: k,
        headerName: P,
        customHeaders: H,
        metricsIntervalInitial: m
      }), q;
    }
    return function (e, r) {
      if ("function" != typeof r && null !== r) throw new TypeError("Class extends value " + String(r) + " is not a constructor or null");
      function n() {
        this.constructor = e;
      }
      t(e, r), e.prototype = null === r ? Object.create(r) : (n.prototype = r.prototype, new n());
    }(i, o), i.prototype.getAllToggles = function () {
      return function (t, e, r) {
        if (r || 2 === arguments.length) for (var n, o = 0, i = e.length; o < i; o++) !n && o in e || (n || (n = Array.prototype.slice.call(e, 0, o)), n[o] = e[o]);
        return t.concat(n || Array.prototype.slice.call(e));
      }([], this.toggles, !0);
    }, i.prototype.isEnabled = function (t) {
      var e,
        r = this.toggles.find(function (e) {
          return e.name === t;
        }),
        n = !!r && r.enabled;
      if (this.metrics.count(t, n), (null == r ? void 0 : r.impressionData) || this.impressionDataAll) {
        var o = this.eventsHandler.createImpressionEvent(this.context, n, t, I, null !== (e = null == r ? void 0 : r.impressionData) && void 0 !== e ? e : void 0);
        this.emit(E.IMPRESSION, o);
      }
      return n;
    }, i.prototype.getVariant = function (t) {
      var r,
        n = this.toggles.find(function (e) {
          return e.name === t;
        }),
        o = (null == n ? void 0 : n.enabled) || !1,
        i = n ? n.variant : x;
      if (i.name && this.metrics.countVariant(t, i.name), this.metrics.count(t, o), (null == n ? void 0 : n.impressionData) || this.impressionDataAll) {
        var s = this.eventsHandler.createImpressionEvent(this.context, o, t, S, null !== (r = null == n ? void 0 : n.impressionData) && void 0 !== r ? r : void 0, i.name);
        this.emit(E.IMPRESSION, s);
      }
      return e(e({}, i), {
        feature_enabled: o
      });
    }, i.prototype.updateToggles = function () {
      return r(this, void 0, void 0, function () {
        var t = this;
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.timerRef || this.fetchedFromServer ? [4, this.fetchToggles()] : [3, 2];
            case 1:
              return e.sent(), [3, 4];
            case 2:
              return this.started ? [4, new Promise(function (e) {
                var r = function () {
                  t.fetchToggles().then(function () {
                    t.off(E.READY, r), e();
                  });
                };
                t.once(E.READY, r);
              })] : [3, 4];
            case 3:
              e.sent(), e.label = 4;
            case 4:
              return [2];
          }
        });
      });
    }, i.prototype.updateContext = function (t) {
      return r(this, void 0, void 0, function () {
        var r;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              return (t.appName || t.environment) && console.warn("appName and environment are static. They can't be updated with updateContext."), r = {
                environment: this.context.environment,
                appName: this.context.appName,
                sessionId: this.context.sessionId
              }, this.context = e(e({}, r), t), [4, this.updateToggles()];
            case 1:
              return n.sent(), [2];
          }
        });
      });
    }, i.prototype.getContext = function () {
      return e({}, this.context);
    }, i.prototype.setContextField = function (t, r) {
      var n, o;
      if (w(t)) this.context = e(e({}, this.context), ((n = {})[t] = r, n));else {
        var i = e(e({}, this.context.properties), ((o = {})[t] = r, o));
        this.context = e(e({}, this.context), {
          properties: i
        });
      }
      this.updateToggles();
    }, i.prototype.removeContextField = function (t) {
      var r;
      w(t) ? this.context = e(e({}, this.context), ((r = {})[t] = void 0, r)) : "object" == typeof this.context.properties && delete this.context.properties[t], this.updateToggles();
    }, i.prototype.init = function () {
      return r(this, void 0, void 0, function () {
        var t, r;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              return [4, this.resolveSessionId()];
            case 1:
              return t = n.sent(), this.context = e({
                sessionId: t
              }, this.context), r = this, [4, this.storage.get(T)];
            case 2:
              return r.toggles = n.sent() || [], !this.bootstrap || !this.bootstrapOverride && 0 !== this.toggles.length ? [3, 4] : [4, this.storage.save(T, this.bootstrap)];
            case 3:
              n.sent(), this.toggles = this.bootstrap, this.sdkState = "healthy", this.readyEventEmitted = !0, this.emit(E.READY), n.label = 4;
            case 4:
              return this.sdkState = "healthy", this.emit(E.INIT), [2];
          }
        });
      });
    }, i.prototype.start = function () {
      return r(this, void 0, void 0, function () {
        var t,
          e = this;
        return n(this, function (r) {
          switch (r.label) {
            case 0:
              return this.started = !0, this.timerRef ? (console.error("Unleash SDK has already started, if you want to restart the SDK you should call client.stop() before starting again."), [2]) : [4, this.ready];
            case 1:
              return r.sent(), this.metrics.start(), t = this.refreshInterval, [4, this.fetchToggles()];
            case 2:
              return r.sent(), t > 0 && (this.timerRef = setInterval(function () {
                return e.fetchToggles();
              }, t)), [2];
          }
        });
      });
    }, i.prototype.stop = function () {
      this.timerRef && (clearInterval(this.timerRef), this.timerRef = void 0), this.metrics.stop();
    }, i.prototype.isReady = function () {
      return this.readyEventEmitted;
    }, i.prototype.getError = function () {
      return "error" === this.sdkState ? this.lastError : void 0;
    }, i.prototype.resolveSessionId = function () {
      return r(this, void 0, void 0, function () {
        var t;
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.context.sessionId ? [2, this.context.sessionId] : [4, this.storage.get("sessionId")];
            case 1:
              return (t = e.sent()) ? [3, 3] : (t = Math.floor(1e9 * Math.random()), [4, this.storage.save("sessionId", t)]);
            case 2:
              e.sent(), e.label = 3;
            case 3:
              return [2, t];
          }
        });
      });
    }, i.prototype.getHeaders = function () {
      var t,
        e = this.usePOSTrequests,
        r = ((t = {})[this.headerName] = this.clientKey, t.Accept = "application/json", t);
      return e && (r["Content-Type"] = "application/json"), this.etag && (r["If-None-Match"] = this.etag), Object.entries(this.customHeaders).filter(a).forEach(function (t) {
        var e = t[0],
          n = t[1];
        return r[e] = n;
      }), r;
    }, i.prototype.storeToggles = function (t) {
      return r(this, void 0, void 0, function () {
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.toggles = t, this.emit(E.UPDATE), [4, this.storage.save(T, t)];
            case 1:
              return e.sent(), [2];
          }
        });
      });
    }, i.prototype.fetchToggles = function () {
      var t;
      return r(this, void 0, void 0, function () {
        var e, r, o, i, s, c, u, l;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              if (!this.fetch) return [3, 9];
              this.abortController && this.abortController.abort(), this.abortController = null === (t = this.createAbortController) || void 0 === t ? void 0 : t.call(this), e = this.abortController ? this.abortController.signal : void 0, n.label = 1;
            case 1:
              return n.trys.push([1, 7, 8, 9]), r = this.usePOSTrequests, o = r ? this.url : function (t, e) {
                var r = new URL(t.toString());
                return Object.entries(e).filter(a).forEach(function (t) {
                  var e = t[0],
                    n = t[1];
                  "properties" === e && n ? Object.entries(n).filter(a).forEach(function (t) {
                    var e = t[0],
                      n = t[1];
                    return r.searchParams.append("properties[".concat(e, "]"), n);
                  }) : r.searchParams.append(e, n);
                }), r;
              }(this.url, this.context), i = r ? "POST" : "GET", s = r ? JSON.stringify({
                context: this.context
              }) : void 0, [4, this.fetch(o.toString(), {
                method: i,
                cache: "no-cache",
                headers: this.getHeaders(),
                body: s,
                signal: e
              })];
            case 2:
              return c = n.sent(), "error" === this.sdkState && c.status < 400 && (this.sdkState = "healthy", this.emit(E.RECOVERED)), c.ok && 304 !== c.status ? (this.etag = c.headers.get("ETag") || "", [4, c.json()]) : [3, 5];
            case 3:
              return u = n.sent(), [4, this.storeToggles(u.toggles)];
            case 4:
              return n.sent(), "healthy" !== this.sdkState && (this.sdkState = "healthy"), this.fetchedFromServer || (this.fetchedFromServer = !0, this.readyEventEmitted = !0, this.emit(E.READY)), [3, 6];
            case 5:
              c.ok || 304 === c.status || (console.error("Unleash: Fetching feature toggles did not have an ok response"), this.sdkState = "error", this.emit(E.ERROR, {
                type: "HttpError",
                code: c.status
              }), this.lastError = {
                type: "HttpError",
                code: c.status
              }), n.label = 6;
            case 6:
              return [3, 9];
            case 7:
              return "object" == typeof (l = n.sent()) && null !== l && "name" in l && "AbortError" === l.name || (console.error("Unleash: unable to fetch feature toggles", l), this.sdkState = "error", this.emit(E.ERROR, l), this.lastError = l), [3, 9];
            case 8:
              return this.abortController = null, [7];
            case 9:
              return [2];
          }
        });
      });
    }, i;
  }(s);
export { E as EVENTS, l as InMemoryStorageProvider, h as LocalStorageProvider, k as UnleashClient, R as resolveFetch };
