import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ItkToastService } from '@itk/ng-toast';

@Injectable()
export class ItkHttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly toast: ItkToastService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((e: unknown) => {
        const err = e as HttpErrorResponse;
        return throwError(() => err);
      }),
    );
  }
}
