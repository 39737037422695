import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { plainToInstance } from 'class-transformer';
import { BrowserEnvConfig } from './app/app.config';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/angular-ivy';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://772b3eed4d246230714e2a9da141e1f1@o851633.ingest.sentry.io/4506549514731520',
  allowUrls: [/.*insurancetoolkits\.com.*/],
  integrations: [
    browserTracingIntegration({
      tracePropagationTargets: [/^https:\/\/insurancetoolkits\.com\//],
    }),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => plainToInstance(BrowserEnvConfig, config))
  .then((useValue) =>
    platformBrowserDynamic([
      { provide: BrowserEnvConfig, useValue },
    ]).bootstrapModule(AppModule),
  )
  // FIXME: use shared logger
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
